import React, { useEffect, useState, useMemo } from "react";
import * as R from "ramda";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
//import { goToTop } from "react-scrollable-anchor";
import { BoxContainer } from "../../components/BoxContainer";
import { MainContent } from "../../components/MainContent";
import { FlexColumn, FlexRow, Grid4, GridContent } from "../../components/Grid";
import { userPageTrigger } from "../../actions/page";
import { setSpecialPaymentAgreementTrigger } from "../../actions/specialPaymentAgreements";
import { fetchSpecialPaymentAgreementTrigger } from "../../actions/specialPaymentAgreements";
import { PageTitleBox } from "../../components/PageTitleBox";
import { BasicText } from "../../components/Fonts/BasicText";
import { ActionTitle } from "../../components/ActionTitle";
import { PageTitleWithRightContent } from "../../components/PageTitleWithRightContent";
import { getCustomerBasicInfo } from "../../selectors/customer";
import { getSpecialPaymentAgreement } from "../../selectors/specialPaymentAgreement";
import { SPECIAL_PAYMENT_AGREEMENTS_TYPES } from "../../constants/specialPaymentAgreements";

import { messages } from "./messages";

export function ChangePage() {
    //const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(userPageTrigger());
    //     dispatch(fetchSpecialPaymentAgreementTrigger());
    //     //goToTop();
    // }, []);

    const customerBasicInfo = useSelector(getCustomerBasicInfo) || {};

    // const selectSpecialPaymentAgreement = useMemo(getSpecialPaymentAgreement, []);
    // const specialPaymentAgreement = useSelector((state) => selectSpecialPaymentAgreement(state));

    // const { loading, error } = useSelector((state) => state.ui.specialPaymentAgreement);

    const { formatMessage: f } = useIntl();

    // const [selectedMonths, setSelectedMonths] = useState([]);

    // const [calendarVisible, toggleCalendarVisible] = useState(false);

    // const handleSelectMonth = (action, selectedMonth) => {
    //     const month = {
    //         year: `${selectedMonth.year}`,
    //         monthWideName: "",
    //         monthAbbreviatedName: "",
    //         monthIndex: selectedMonth.month,
    //         id: selectedMonth.id
    //     };

    //     if (action === "add") {
    //         setSelectedMonths((prevMonths) => [...prevMonths, month]);
    //     }
    //     if (action === "remove") {
    //         setSelectedMonths((prevMonths) => {
    //             const index = R.findIndex(R.propEq("id", month.id))(prevMonths);
    //             prevMonths.splice(index, 1);
    //             return [...prevMonths];
    //         });
    //     }
    // };

    // const handelCancel = () => {
    //     toggleCalendarVisible(false);
    //     setSelectedMonths([...specialPaymentAgreement]);
    // };

    // const handleSave = () => {
    //     const filtered = R.filter(R.has("id"))(selectedMonths);
    //     const monthsToSave = filtered.map((item) => ({
    //         paymentAgreementType: SPECIAL_PAYMENT_AGREEMENTS_TYPES.PAYMENT_FREE_MONTH,
    //         startDate: item.id,
    //         endDate: item.id
    //     }));

    //     dispatch(setSpecialPaymentAgreementTrigger(monthsToSave));
    // };

    // const isDisabled = () => specialPaymentAgreement.length === selectedMonths.length || loading;

    // useEffect(() => {
    //     setSelectedMonths([...specialPaymentAgreement]);
    // }, [specialPaymentAgreement]);

    const ChangePageMargins = styled.span`
        display: block;
        margin: 24px 0px 24px 0px;
    `;
    const ChangPageMixmargin = styled.span`
        display: block;
        margin-top: 24px;
    `;

    return (
        <FlexColumn>
            <PageTitleBox>
                <PageTitleWithRightContent title={f(messages.pagetitle)}>
                    <BasicText>{`${f(messages.customernumber)} ${
                        customerBasicInfo.customerId
                    }`}</BasicText>
                </PageTitleWithRightContent>
            </PageTitleBox>
            <FlexRow>
                <Grid4>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <ActionTitle title={f(messages.subtitle)} />
                                <ChangePageMargins>
                                    <BasicText>{f(messages.info)}</BasicText>
                                </ChangePageMargins>
                                <BasicText>{f(messages.customerservicecontact)}</BasicText>

                                <ChangPageMixmargin>
                                    <BasicText>
                                        <FormattedMessage {...messages.phonenumber} />
                                    </BasicText>
                                    <br />
                                    <BasicText>
                                        <FormattedMessage {...messages.email} />
                                    </BasicText>
                                </ChangPageMixmargin>
                                {/* NOTE: Remove comments to enable payment free month calendar */}
                                {/*<FlexRow>
                                    <ActionTitle title="Maksuvapaa kuukausi">
                                        {!calendarVisible && (
                                            <CommonButton
                                                onClick={() =>
                                                    toggleCalendarVisible(!calendarVisible)
                                                }>
                                                {f(messages.addmonthbutton)}
                                            </CommonButton>
                                        )}
                                        {calendarVisible && (
                                            <>
                                                <ChangePageMargin>
                                                    <LightButton onClick={() => handelCancel()}>
                                                        {f(messages.cancelbutton)}
                                                    </LightButton>
                                                </ChangePageMargin>
                                                <CommonButton
                                                    onClick={() => handleSave()}
                                                    disabled={isDisabled()}>
                                                    {loading && (
                                                        <Icon
                                                            icon={["fas", "circle-notch"]}
                                                            color={colors.white}
                                                            spin
                                                        />
                                                    )}
                                                    {f(messages.savebutton)}
                                                </CommonButton>
                                            </>
                                        )}
                                    </ActionTitle>
                                                    </FlexRow>*/}
                                {/*error && <ErrorMessage>{f(messages.errormessage)}</ErrorMessage>*/}
                                {/*<IngressText paymentFreeMonths={specialPaymentAgreement} />*/}
                                {/*calendarVisible && (
                                    <PaymentFreeCalendar
                                        selectedMonths={selectedMonths}
                                        existentMonths={specialPaymentAgreement}
                                        setMonth={handleSelectMonth}
                                    />
                                )*/}
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid4>
            </FlexRow>
        </FlexColumn>
    );
}
