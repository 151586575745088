import { takeLatest, put } from "@redux-saga/core/effects";
import { FRONT_PAGE_TRIGGER } from "../constants/page";
/* DEPRECATED */
import { fetchPaymentPlansTrigger } from "../actions/paymentPlans";
/* DEPRECATED */
import { fetchAccountStatementsTrigger } from "../actions/account";
import { fetchRemainingDebtTrigger } from "../actions/remainingDebt";
import { fetchContentfulNewsTrigger } from "../actions/contentful";

import { fetchDebtStatusTrigger } from "../actions/debtStatus";

export function* watchFrontPageLoad() {
    yield takeLatest(FRONT_PAGE_TRIGGER, handleFrontPageLoad);
}

export function* handleFrontPageLoad(action) {
    yield put(fetchContentfulNewsTrigger(action.payload));
    /** Remove get remainng Debt as it is not used anywhere at this point */
    //yield put(fetchRemainingDebtTrigger());
    /* DEPRECATED */
    yield put(fetchAccountStatementsTrigger());
    /* DEPRECATED */
    // yield put(fetchPaymentPlansTrigger());
    yield put(fetchDebtStatusTrigger());
}
