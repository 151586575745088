import React from "react";
import { fetchDocument } from "../../util/api";
import { StyledStatementLink } from "./styled";

export const StatementLink = ({ documentId, status, token, children }) => {
    return (
        <StyledStatementLink onClick={() => fetchDocument({ documentId, token })} status={status}>
            {children}
        </StyledStatementLink>
    );
};
