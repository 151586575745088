import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { BasicText } from "../Fonts/BasicText";
import { Tooltip } from "../Tooltip";
import { ActionTitle } from "../ActionTitle";

import { messages } from "./messages";

export function PayNow() {
    const { activeAccountInfo, remainingDebt } = useSelector((state) => {
        return {
            activeAccountInfo: state.accounts.activeAccountInfo,
            remainingDebt: state.remainingDebt.remainingDebt
        };
    });
    const { remainingPrincipal } = activeAccountInfo;
    const totalRemainingDebt =
        remainingDebt && remainingDebt.remainingDebts ? remainingDebt.remainingDebts : 0;
    const totalExpenses = Math.abs(totalRemainingDebt - remainingPrincipal);
    const totalPrincipal = remainingPrincipal;

    const { formatMessage: f } = useIntl();

    return (
        <>
            <ActionTitle title={f(messages.title)}>
                <Tooltip>{f(messages.tooltip)}</Tooltip>
            </ActionTitle>
            <BasicText>
                Jos haluat maksaa lainasi kokonaan takaisin, olethan yhteydessä asiakaspalveluumme
            </BasicText>
            <br />
            <br />
            <BasicText bold>Puhelinpalvelu</BasicText>
            <br />
            <BasicText>ma-pe klo 9–18</BasicText>
            <br />
            <BasicText>020 741 2040</BasicText>
            <br />
            <BasicText bold>Sähköposti</BasicText>
            <br />
            <BasicText>
                <a href="mailto: asiakasinfo@svea.ostosraha.fi">asiakasinfo@svea.ostosraha.fi</a>
            </BasicText>
            <br />
            <br />
            <br />
            <br />
            {/*<PayNowContainer>
                <PayNowInfo>
                    <PayNowInfoRow>
                        <BasicText>{f(messages.info)}</BasicText>
                    </PayNowInfoRow>
                    <PayNowInfoRow>
                        <BasicText>
                            <Currency>{totalPrincipal}</Currency>
                        </BasicText>
                    </PayNowInfoRow>
                </PayNowInfo>
                <PayNowInfo>
                    <PayNowInfoRow>
                        <BasicText>{f(messages.fees)}</BasicText>
                    </PayNowInfoRow>
                    <PayNowInfoRow>
                        <BasicText>
                            <Currency>{totalExpenses}</Currency>
                        </BasicText>
                    </PayNowInfoRow>
                </PayNowInfo>
            </PayNowContainer>
            <PayNowContainer>
                <BoxContent>
                    <PayNowInfoRow>
                        <BoxTitle>{f(messages.sum)}</BoxTitle>
                        <BoxAmount>
                            <Currency>{totalRemainingDebt}</Currency>
                        </BoxAmount>
                    </PayNowInfoRow>
                </BoxContent>
            </PayNowContainer>*/}
            {/*<PayNowAccount>
                <PayNowButtonContainer
                    onClick={() => {
                        history.push("/loan/calculator");
                    }}>
                    <RaisedButton>
                        <BasicText>
                            <FormattedMessage {...messages.button} />
                        </BasicText>
                    </RaisedButton>
                </PayNowButtonContainer>
            </PayNowAccount>*/}
        </>
    );
}
