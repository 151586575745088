import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
//import { goToAnchor, removeHash, configureAnchors } from "react-scrollable-anchor";

import { loanPageTrigger } from "../../actions/page";
import { fetchPdfDocumentTrigger, fetchPdfDocumentReset } from "../../actions/fetchPdf";
import { FlexColumn, Grid2, Grid4, GridContainer, GridContent } from "../../components/Grid";
import { BoxContainer } from "../../components/BoxContainer";
import { MainContent } from "../../components/MainContent";
import { PageTitleBox } from "../../components/PageTitleBox";
import { BasicText } from "../../components/Fonts/BasicText";
import { CommonButton } from "../../components/CommonButton";
import { BoxContent, BoxTitle, BoxAmount, BoxLabel } from "../../components/BoxContent";
import { ModalDialog } from "../../components/ModalDialog";
import { colors } from "../../constants/styles";
import { InstallmentsButton } from "../../components/InstallmentsButton";
import { InvoicesTable, MobileInvoicesTable, StatementLink } from "../../components/InvoicesTable";
import { NextInstallmentButton } from "../../components/NextInstallmentButton";
import { DEFAULT_FORMAT_DATE } from "../../constants/dateTime";
import { Icon } from "../../components/Icon";

import { InvoicesArchive } from "./InvoicesArchive";
import * as Modals from "./Modals";

import { getActiveAccountInfo, getRemainingPrincipal } from "../../selectors/activeAccountInfo";
import { getDocumentsWithToken } from "../../selectors/documents";
import { selectEntities } from "../../selectors/debtStatus";
import { getCustomerBasicInfo } from "../../selectors/customer";

import {
    ButtonsContainer,
    LoanContent,
    NoteContainer,
    InstallmentsButtonsContainer,
    InstallmentsButtonContainer,
    TableContainer,
    MobileTableContainer,
    SettingsButtonText
} from "./styled";
import { PayNowCalculator } from "./PayNowCalculator";
import { ActionTitle } from "../../components/ActionTitle";
import { getCurrency, Currency } from "../../components/Currency";
import { PageTitleWithRightContent } from "../../components/PageTitleWithRightContent";
import { getFetchPdfStatus } from "../../selectors/fetchPdf";

import { messages } from "./messages";
import { CELL_TYPES } from "../../components/InvoicesTable/constants";
import { useRef } from "react";

export function LoanPage() {
    const dispatch = useDispatch();
    const { formatMessage: f } = useIntl();
    const history = useHistory();
    const route = useRouteMatch();
    const isFeature = route.params.feature;
    const [summaryModal, toggleSummaryModal] = useState(false);
    const [contractsModal, toggleContractsModal] = useState(false);
    const [loanSettingsModal, toggleLoanSettingsModal] = useState(false);

    const selectDocumentIdAndToken = useMemo(getDocumentsWithToken, []);
    const { documents, token } = useSelector((state) => selectDocumentIdAndToken(state));

    const { accountNumber, appliedAmount, monthlyCost, interestRate } = useSelector(
        getActiveAccountInfo
    );
    const { loading } = useSelector(getFetchPdfStatus);
    const selectRemainingPrincipal = useMemo(getRemainingPrincipal, []);
    const remainingPrincipal = useSelector((state) => selectRemainingPrincipal(state));
    const customerBasicInfo = useSelector(getCustomerBasicInfo) || {};

    // const loanContractData = { token, documentId: promissionaryDocumentId };
    const formattedNow = format(new Date(), DEFAULT_FORMAT_DATE);

    const getEntities = useMemo(selectEntities, []);
    const entities = useSelector((state) => getEntities(state));

    const statements = entities
        .filter((item) => item.statement)
        .map((item) => ({
            seriesNum: item.seriesNum,
            title: item.title,
            statementStatus: item.statement.status,
            dueDate: item.statement.dueDate,
            statementAmount: item.statement.statementAmount,
            unpaidAmount: item.statement.unpaidAmount,
            documentId: item.statement.documentId
        }))
        .sort((a, b) => (a.seriesNum < b.seriesNum ? 1 : -1));

    const statementsTableColumns = [
        {
            Header: f(messages.statementsTableColumns.number),
            accessor: "seriesNum",
            Cell: ({ cell }) => {
                return cell.row.original.documentId ? (
                    <StatementLink
                        documentId={cell.row.original.documentId}
                        token={token}
                        status={cell.row.original.statementStatus}>
                        {cell.value}
                    </StatementLink>
                ) : (
                    cell.value
                );
            }
        },
        {
            Header: f(messages.statementsTableColumns.title),
            accessor: "title",
            Cell: ({ cell }) =>
                cell.row.original.documentId ? (
                    <StatementLink
                        documentId={cell.row.original.documentId}
                        token={token}
                        status={cell.row.original.statementStatus}>
                        {`${f(messages.statementsTableColumns.statementtitle)} ${cell.value}`}
                    </StatementLink>
                ) : (
                    `${f(messages.statementsTableColumns.statementtitle)} ${cell.value}`
                )
        },
        {
            Header: f(messages.statementsTableColumns.status),
            accessor: "statementStatus",
            Cell: ({ cell }) => f(messages[cell.value])
        },
        {
            Header: f(messages.statementsTableColumns.duedate),
            accessor: "dueDate"
        },
        {
            Header: f(messages.statementsTableColumns.unpaidamount),
            accessor: "unpaidAmount",
            Cell: ({ cell }) => <Currency>{cell.value}</Currency>
        },
        {
            Header: f(messages.statementsTableColumns.total),
            accessor: "statementAmount",
            Cell: ({ cell }) => <Currency>{cell.value}</Currency>
        }
    ];

    // useLayoutEffect(() => {
    //     configureAnchors({ offset: -165 });
    // }, []);

    useEffect(() => {
        dispatch(loanPageTrigger());
        dispatch(fetchPdfDocumentReset());
        // goToAnchor("statements");
        // removeHash();
    }, [dispatch]);

    const handleClick = () => {
        history.push("/change");
    };

    const setInfo = () => {
        return [
            {
                translationKey: "accountNumber",
                value: accountNumber
            },
            {
                translationKey: "appliedAmount",
                value: getCurrency(appliedAmount)
            },
            {
                translationKey: "loanMaturity",
                value: `${entities.length} kk`
            },
            {
                translationKey: "installmentAmount",
                value: `${entities.length} kpl`
            },
            {
                translationKey: "installmentInterval",
                value: "1 kk"
            },
            {
                translationKey: "monthlyCost",
                value: getCurrency(monthlyCost)
            },
            {
                translationKey: "interestRate",
                value: `${(interestRate * 100).toFixed(2)} %`
            },
            {
                translationKey: "firstDueDate",
                value: entities[0].dueDate
            },
            {
                translationKey: "lastDueDate",
                value: entities[entities.length - 1].dueDate
            }
        ];
    };

    const info = useRef([]);
    let tableData = [];

    let pdfData = {
        info: info.current,
        table: {
            tableData
        },
        params: {
            source: "OR-PdfPaymentPlan",
            showTableFoot: true
        }
    };

    const pdfParams = {
        token,
        endpoint: "payment-plan"
    };

    const callbackValue = useCallback(() => {
        if (entities.length > 0) {
            info.current = setInfo();
            return {
                ...pdfData,
                info: info.current,
                table: {
                    tableData: entities
                }
            };
        }
    }, [entities]);

    pdfData = callbackValue();

    if (isFeature && isFeature === "calculator") {
        return <PayNowCalculator />;
    }

    return (
        <FlexColumn>
            <PageTitleBox>
                <PageTitleWithRightContent title={f(messages.pagetitle)}>
                    <BasicText>{`${f(messages.customernumber)} ${
                        customerBasicInfo.customerId
                    }`}</BasicText>
                </PageTitleWithRightContent>
            </PageTitleBox>
            <GridContainer>
                <Grid2>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <ActionTitle title={f(messages.info.subtitle)} />
                                <LoanContent>
                                    <BoxContent center>
                                        <BoxTitle>
                                            {`${f(messages.info.remainingtoday)} ${formattedNow}`}
                                        </BoxTitle>
                                        <BoxAmount>
                                            <Currency>{remainingPrincipal}</Currency>
                                        </BoxAmount>
                                    </BoxContent>
                                    <NoteContainer>
                                        {f(
                                            messages.info.remaininginfo,
                                            messages.info.remaininginfo.values
                                        )}
                                    </NoteContainer>
                                    <ButtonsContainer>
                                        <CommonButton
                                            onClick={() => toggleSummaryModal(!summaryModal)}>
                                            {f(messages.info.modalsummary)}
                                        </CommonButton>
                                        <CommonButton
                                            onClick={() => toggleContractsModal(!contractsModal)}>
                                            {f(messages.info.modalcontracts)}
                                        </CommonButton>
                                        <ModalDialog
                                            open={summaryModal}
                                            closeDialog={() => toggleSummaryModal(false)}
                                            headline={f(messages.info.modalsummary)}>
                                            <Modals.LoanSummary />
                                        </ModalDialog>
                                        <ModalDialog
                                            open={contractsModal}
                                            closeDialog={() => toggleContractsModal(false)}
                                            headline={f(messages.info.modalcontracts)}>
                                            <Modals.LoanContracts data={{ documents, token }} />
                                        </ModalDialog>
                                    </ButtonsContainer>
                                </LoanContent>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid2>
                <Grid2>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <LoanContent>
                                    <InstallmentsButtonsContainer>
                                        <InstallmentsButtonContainer>
                                            <NextInstallmentButton />
                                        </InstallmentsButtonContainer>
                                        <InstallmentsButtonContainer>
                                            <InstallmentsButton onClick={() => handleClick()}>
                                                <BoxLabel>
                                                    {f(messages.changepaymentinfobutton)}
                                                </BoxLabel>
                                            </InstallmentsButton>
                                        </InstallmentsButtonContainer>
                                    </InstallmentsButtonsContainer>
                                </LoanContent>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid2>
            </GridContainer>
            <GridContainer>
                <Grid4>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <ActionTitle title={f(messages.statementstable)}>
                                    <CommonButton
                                        onClick={() => toggleLoanSettingsModal(!loanSettingsModal)}>
                                        <Icon icon={["fas", "cog"]} color={colors.white} />
                                        <SettingsButtonText>
                                            {f(messages.statementssettings)}
                                        </SettingsButtonText>
                                    </CommonButton>
                                    <ModalDialog
                                        open={loanSettingsModal}
                                        closeDialog={() => toggleLoanSettingsModal(false)}
                                        headline={f(messages.statementssettings)}>
                                        <Modals.LoanSettings
                                            close={() => toggleLoanSettingsModal(false)}
                                        />
                                    </ModalDialog>
                                </ActionTitle>
                                <TableContainer>
                                    <InvoicesTable
                                        tableData={statements}
                                        tableColumns={statementsTableColumns}
                                        token={token}
                                        noButton={true}
                                    />
                                </TableContainer>
                                <MobileTableContainer id={"statements"}>
                                    <MobileInvoicesTable
                                        tableData={statements}
                                        token={token}
                                        noButton={true}
                                    />
                                </MobileTableContainer>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid4>
            </GridContainer>
            <GridContainer>
                <Grid4>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <InvoicesArchive
                                    title={f(messages.archivetable)}
                                    tableData={entities}
                                    token={token}
                                    pdfData={pdfData}
                                    pdfHeaders={[
                                        {
                                            accessor: "seriesNum",
                                            translationKey: "seriesNum",
                                            type: CELL_TYPES.TEXT
                                        },
                                        {
                                            accessor: "dueDate",
                                            translationKey: "dueDate",
                                            type: CELL_TYPES.DATE
                                        },
                                        {
                                            accessor: "amortization",
                                            translationKey: "amortization",
                                            type: CELL_TYPES.CURRENCY
                                        },
                                        {
                                            accessor: "interest",
                                            translationKey: "interest",
                                            type: CELL_TYPES.CURRENCY
                                        },
                                        {
                                            accessor: "totalAmount",
                                            translationKey: "totalAmount",
                                            type: CELL_TYPES.CURRENCY
                                        }
                                    ]}
                                    pdfParams={pdfParams}
                                    pdfDispatch={(data, params) =>
                                        dispatch(fetchPdfDocumentTrigger(data, params))
                                    }
                                    loading={loading}
                                />
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid4>
            </GridContainer>
        </FlexColumn>
    );
}
