import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import * as R from "ramda";
import { GenericTable } from "../../GenericTable";
import { StatementLink } from "../StatementLink";
import { getCurrency, Currency } from "../../Currency";
import { StyledStatementContainer, StyledStatementInfo, StyledStatementText } from "../styled";
import { CELL_TYPES } from "../constants";

import { messages } from "./messages";

export const MobileInvoicesTable = ({
    tableData,
    token,
    noButton,
    pdfData,
    pdfParams,
    pdfDispatch,
    pdfHeaders,
    loading
}) => {
    const { formatMessage: f } = useIntl();

    const StatementTitle = ({ documentId, token, children, status }) =>
        documentId ? (
            <StatementLink documentId={documentId} token={token} status={status}>
                {children}
            </StatementLink>
        ) : (
            children
        );

    const CellContent = ({ title, statementStatus, dueDate, totalAmount, documentId, status }) => {
        return (
            <StyledStatementContainer>
                <StyledStatementInfo>
                    <StatementTitle documentId={documentId} token={token} status={status}>
                        {title}
                    </StatementTitle>
                    <StyledStatementText>{statementStatus}</StyledStatementText>
                    <StyledStatementText>{dueDate}</StyledStatementText>
                </StyledStatementInfo>
                <StyledStatementInfo>
                    <StyledStatementText bold>
                        <Currency>{totalAmount}</Currency>
                    </StyledStatementText>
                </StyledStatementInfo>
            </StyledStatementContainer>
        );
    };

    const columns = useMemo(
        () => [
            {
                accessor: "title",
                Cell: ({
                    cell: {
                        row: { original }
                    }
                }) => {
                    return (
                        <CellContent
                            title={`${f(messages.statementtitle)} ${original.title}`}
                            statementStatus={f(messages[original.statementStatus])}
                            dueDate={original.dueDate}
                            totalAmount={original.statementAmount}
                            documentId={original.documentId}
                            status={original.statementStatus}
                        />
                    );
                }
            }
        ],
        [f]
    );

    let dataPdf = null;

    const modifyData = (tableRow) => {
        const omitProps = columns
            .filter((item) => !item.exportToPdf)
            .map((column) => column.accessor);

        let currentRow = R.omit(omitProps, tableRow);
        pdfHeaders.forEach((header) => {
            const { accessor, type } = header;
            if (type === CELL_TYPES.DATE) {
                const newValue = currentRow[accessor];
                currentRow = {
                    ...currentRow,
                    [accessor]: newValue
                };
            }
            if (type === CELL_TYPES.CURRENCY) {
                const newValue = getCurrency(currentRow[accessor]);
                currentRow = {
                    ...currentRow,
                    [accessor]: newValue
                };
            }
        });

        return currentRow;
    };

    if (pdfData) {
        const tableData = R.map(modifyData, pdfData.table.tableData);

        dataPdf = {
            ...pdfData,
            table: {
                headers: pdfHeaders,
                tableData
            }
        };
    }

    return (
        <GenericTable
            columns={columns}
            data={tableData}
            noHead={true}
            noButton={noButton}
            pdfData={dataPdf}
            pdfParams={pdfParams}
            pdfDispatch={pdfDispatch}
            loading={loading}
            noFooter={true}
        />
    );
};
