import { takeEvery, put } from "@redux-saga/core/effects";
import { USER_PAGE_TRIGGER } from "../constants/page";
/* DEPRECATED */
import { fetchAccountStatementsTrigger } from "../actions/account";
/* DEPRECATED */
import { fetchPaymentPlansTrigger } from "../actions/paymentPlans";

import { fetchDebtStatusTrigger } from "../actions/debtStatus";

export function* watchUserPageLoad() {
    yield takeEvery(USER_PAGE_TRIGGER, handleUserPageLoad);
}

export function* handleUserPageLoad() {
    /* DEPRECATED */
    yield put(fetchAccountStatementsTrigger());
    /* DEPRECATED */
    // yield put(fetchPaymentPlansTrigger());

    yield put(fetchDebtStatusTrigger());
}
